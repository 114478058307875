import { computed } from 'vue';
import { useStore } from 'vuex'

const useAuth = () => {
    const store = useStore()

    const loginUser = async (user) => {
        const session = await store.dispatch('auth/loginUser', user)
        return session
    }

    return {
        loginUser,
        authStatus: computed(() => store.getters['auth/currentState'])
    }
}

export default useAuth