<template>
  <div class="form-group">
    <div class="mb-2" :class="hasIcon(icon)">
      <span v-if="iconDir === 'left'" class="input-group-text">
        <i :class="getIcon(icon)"></i>
      </span>
      <input
        :type="type"
        :accept="accept"
        :multiple="multiple"
        class="form-control"
        :class="getClasses(size, valid)"
        :name="name"
        :id="id"
        :value="modelValue"
        :placeholder="placeholder"
        :required="isRequired"
        @blur="blurValidation"
        @keyup="endWriteValidation($event.target.value)"
        @paste="pasteDetectAction"
        @input="pasteAction($event.target.value)"
        @change="
          type === 'file' ? $emit('change-image', multiple === 'multiple' ? $event.target.files : $event.target.files[0]) : null
        "
      />
      <span v-if="iconDir === 'right'" class="input-group-text">
        <i :class="getIcon(icon)"></i>
      </span>
    </div>
    <argon-alert size="sm" color="error" v-if="showError">
      <strong>{{ `'${name}'` }}</strong> Es un campo obligatorio!
    </argon-alert>
  </div>
</template>

<script>
import ArgonAlert from "./ArgonAlert.vue";
export default {
  name: "argon-input",
  components: { ArgonAlert },
  props: {
    size: {
      type: String,
      default: "default",
    },
    multiple: String,
    icon: String,
    iconDir: String,
    name: String,
    id: String,
    modelValue: String,
    placeholder: String,
    type: String,
    isRequired: Boolean,
    accept: String,
  },
  data() {
    return {
      showError: false,
      valid: this.inputValid(),
      timeout: setTimeout(() => {
        clearTimeout(this.timeout);
      }, 1),
    };
  },
  emits: ["update:modelValue", "valid", "change-image"],
  methods: {
    getClasses: (size, valid) => {
      let sizeValue, isValidValue;

      sizeValue = size ? `form-control-${size}` : null;

      isValidValue = valid ? `${valid}` : "invalid";

      return `${sizeValue} ${isValidValue}`;
    },
    getIcon: (icon) => (icon ? icon : null),
    hasIcon: (icon) => (icon ? "input-group" : null),
    pasteAction(value) {
      if (this.pasteDetect) {
        if (value.length !== 0) {
          this.showError = false;
          this.$emit("valid", { inputIndex: parseInt(this.id), valid: true });
        } else {
          this.showError = true;
          this.$emit("valid", { inputIndex: parseInt(this.id), valid: false });
        }
        this.$emit("update:modelValue", value);
        this.pasteDetect = false;
      }
    },
    pasteDetectAction() {
      this.pasteDetect = true;
    },
    blurValidation() {
      if (this.isRequired) {
        if (this.modelValue.length === 0) {
          this.showError = true;
          this.$emit("valid", { inputIndex: parseInt(this.id), valid: false });
        }
      }
    },
    endWriteValidation(value) {
      this.showError = false;
      this.$emit("update:modelValue", value);
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (this.isRequired) {
          if (value.length !== 0) {
            this.$emit("update:modelValue", value);
            this.$emit("valid", { inputIndex: parseInt(this.id), valid: true });
          } else {
            this.showError = true;
            this.$emit("valid", {
              inputIndex: parseInt(this.id),
              valid: false,
            });
          }
        }
        clearTimeout(this.timeout);
      }, 700);
    },
    inputValid() {
      let valid = true;
      if (this.isRequired) {
        if (this.modelValue.length === 0) {
          valid = false;
          this.$emit("valid", { inputIndex: parseInt(this.id), valid: false });
        } else valid = true;
      }
      return valid;
    },
  },
};
</script>
