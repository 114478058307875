<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <img src="../../assets/img/mb.png" alt="Image placeholder" class="card-img-top" />
                  <!-- <h4 class="font-weight-bolder">MOTO BOUTIQUE</h4> -->
                  <p class="mb-0">Ingresa tu correo y contraseña.</p>
                </div>
                <div class="card-body">
                  <form @submit.prevent="onSubmit" role="form">
                    <div class="mb-3">
                      <argon-input v-model="userForm.email" type="email" placeholder="Email" name="email" size="lg" />
                    </div>
                    <div class="mb-3">
                      <argon-input v-model="userForm.password" type="password" placeholder="Password" name="password"
                        size="lg" />
                    </div>
                    <!-- <argon-switch id="rememberMe">Recordar</argon-switch> -->

                    <div class="text-center">
                      <argon-button type="submit" class="mt-4" variant="gradient" color="primary" fullWidth size="lg">
                        Ingresar
                      </argon-button>
                    </div>
                  </form>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <!-- <p class="mx-auto mb-4 text-sm">
                    <a href="/forgot-password" class="text-success text-gradient font-weight-bold">Recuperar
                      contraseña?</a>
                  </p> -->
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column">
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="background-image: url('https://www.libramientoslp.mx/images/bgContacto.png');
                      background-size: cover;">
                <span class="mask bg-gradient-primary opacity-6"></span>
                <h1 class="mt-5 text-white font-weight-bolder position-relative">"Moto Boutique, S.A. de C.V."</h1>
                <h4 class="text-white position-relative">
                  Contamos con la exclusividad de las marcas Joe Rocket, Speed And Strength y Answer Racing.
                  Distribuimos a todo el territorio mexicano desde:
                  Blvd. Antonio Rocha Cordero Km 7.6, Colonia Tierra Blanca, C.P. 78364, San Luis Potosí, S.L.P
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import useAuth from "../composables/useAuth";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import Swal from 'sweetalert2';

const body = document.getElementsByTagName("body")[0];

export default {
  setup() {
    const router = useRouter();
    const { loginUser } = useAuth();

    // const userForm = ref({
    //   email: "j.rojas@motoboutique.mx",
    //   password: "Moto123-2024"
    // });

    const userForm = ref({
      email: "",
      password: ""
    });

    const onSubmit = () => {
      Swal.fire({
        title: 'Iniciando sesión...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      loginUser(userForm.value)
        .then(() => {
          Swal.close()
          router.replace({ name: 'Clients' })
        }
        )
        .catch(() => {
          Swal.close()
          Swal.fire({
            title: 'Datos de inicio de sesión incorrectos',
            text: 'Por favor revisa que sean correctos e intentalo nuevamente.',
            icon: 'question',
            confirmButtonText: 'Aceptar',
            customClass: {
              confirmButton: 'btn btn-warning btn-block m-3 bg-primary'
            },
            buttonsStyling: false,  // Desactivar el estilo predeterminado
          })
        })
    };


    return {
      userForm,
      onSubmit
    };
  },
  name: "signin",
  components: {
    ArgonInput,
    ArgonButton,
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  }
};
</script>
